
import Splide from '@splidejs/splide';

// import {Video} from '@splidejs/splide-extension-video';
import {Video} from './ComponentsForked/splide-extension-video.cjs';

import '@splidejs/splide/dist/css/splide-core.min.css';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css';
import '../scss/video-slider.scss'

function createSplideVideo(element) {
    var splide = new Splide(element, {
        heightRatio: 0.5625,
        cover: true,
        type: 'loop',
        interval: 12000000,
        lazyLoad: 'nearby',
        // pauseOnFocus: true,
        // pauseOnHover: true,
        clones: 0,
        video: {
            disableOverlayUI: true,
            loop: element.classList.contains('loop-video'), // у слайдера с name-field input'ом делаем loop одного видео, чтобы не переключалось видео пока пользователь вводит имя в input
            mute: true,
            autoplay: true,
            playerOptions: {
                htmlVideo: {
                    disablePictureInPicture: true,
                    playsInline: true,
                    muted: true,
                    autoplay: true,
                    width: 1920,
                    height: 1080,
                }
            }
        },
    });

    splide.mount({Video});

    splide.on('video:ended', function (player) {
        splide.go('>');
        setTimeout(() => {
            player.ui.video.getElementsByTagName('video')[0].currentTime = 0;
        }, 500);
    });
    splide.on('video:play', function (player) {
        // const input = document.querySelector('.splide__slide.is-visible .name-field');
        // if (typeof input !== 'undefined') {
        //     input.focus();
        //     input.setSelectionRange(input.getAttribute('value').length, input.getAttribute('value').length);
        // }
        player.ui.video.parentNode.style.background = 'none';
    });
    splide.on('click', function (slide) {
        slide.slide.getElementsByTagName('video')[0].classList.add("user-clicked");
    });
    splide.on('inactive', function (slide) {
        slide.slide.getElementsByTagName('video')[0].classList.remove("user-clicked");
        slide.slide.getElementsByTagName('video')[0].muted = true;
    });
    splide.on('moved', function () {
        // const input = document.querySelector('.splide__slide.is-visible .name-field');
        // if (typeof input !== 'undefined') {
        //     input.focus();
        //     input.setSelectionRange(input.getAttribute('value').length, input.getAttribute('value').length);
        // }
        if (typeof ym !== "undefined") {
            ym(29900459,'reachGoal','homepageNextVideoSlide');
        }
    });
}

function isInViewport(element, fraction = 0.1) {
    var viewportOffset = element.getBoundingClientRect();

    var x = viewportOffset.left /*video.offsetLeft*/,
        y = viewportOffset.top + window.pageYOffset /*video.offsetTop*/,
        w = element.offsetWidth,
        h = element.offsetHeight,
        r = x + w, //right
        b = y + h, //bottom
        visibleX, visibleY, visible;

    visibleX = Math.max(0, Math.min(w, window.pageXOffset + window.innerWidth - x, r - window.pageXOffset));
    visibleY = Math.max(0, Math.min(h, window.pageYOffset + window.innerHeight - y, b - window.pageYOffset));

    visible = visibleX * visibleY / (w * h);

    return visible > fraction;
}

document.addEventListener( 'DOMContentLoaded', function() {

    var splideVideos = document.getElementsByClassName( 'splide-video' );

    function checkScroll() {
        var videos = document.querySelectorAll(".splide__video__wrapper video, .homepage .top-video-bg-container video");

        for(var i = 0; i < videos.length; i++) {
            var video = videos[i];
            if (isInViewport(video, 0.2)) {
                if (!video.classList.contains('user-clicked')) {
                    video.play();
                }
            } else {
                video.pause();
            }

        }

        for ( var k = 0; k < splideVideos.length; k++ ) {
            if (isInViewport(splideVideos[k]) && !splideVideos[k].classList.contains('is-initialized')) {
                createSplideVideo(splideVideos[k]);
            }
        }
    }

    for ( var k = 0; k < splideVideos.length; k++ ) {
        if (isInViewport(splideVideos[k]) && !splideVideos[k].classList.contains('is-initialized')) {
            createSplideVideo(splideVideos[k]);
        }
    }

    window.addEventListener('scroll', checkScroll, false);
    window.addEventListener('resize', checkScroll, false);

} );